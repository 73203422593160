import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Packages from './components/Packages'
import Gallery from './components/Gallery'
import Contact from './components/Contact'
import Container from 'react-bootstrap/Container'
import logo from './assets/mhvaletinglogo.png'

const App = () => {
  return (
    <div className='background'>
      <Navbar expand='sm' className='w-100 navbar-dark'>
        <Container>
          <Navbar.Brand href='/'><img src={logo} alt='Logo' /></Navbar.Brand>
          <h2 id='name'>MH Valeting</h2>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse className='justify-content-end'>
            <a href='#packages'>Packages </a>
            <a href='#contact'>Contact </a>
            <a href='#gallery'>Gallery </a>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <section>
        <Packages />
        <Gallery />
        <Contact />
      </section>
    </div>
  )
}

export default App
