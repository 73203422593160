import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import landscape1 from '../assets/landscape1.jpg'
import ferrari from '../assets/ferrari.jpg'
import landscape3 from '../assets/landscape3.JPG'
import landscape4 from '../assets/landscape4.JPG'
import landscape5 from '../assets/landscape5.JPG'
import landscape6 from '../assets/landscape6.JPEG'
import landscape7 from '../assets/landscape7.jpg'
import landscape8 from '../assets/landscape8.JPG'
import landscape9 from '../assets/landscape9.JPG'
import landscape10 from '../assets/landscape10.JPG'
// import landscape11 from '../assets/landscape11.JPG'
// import landscape12 from '../assets/landscape12.JPG'
import landscape13 from '../assets/landscape13.jpg'
import landscape14 from '../assets/landscape14.jpg'
import landscape15 from '../assets/landscape15.jpg'
import landscape16 from '../assets/landscape16.jpg'
import landscape17 from '../assets/landscape17.jpg'
import landscape18 from '../assets/landscape18.jpg'
import landscape19 from '../assets/landscape19.jpg'
import landscape20 from '../assets/landscape20.jpg'
// import landscape21 from '../assets/landscape21.jpg'
// import landscape22 from '../assets/landscape22.jpg'


const Gallery = () => {
  return (
    <div id='gallery' className='content'>
      <Carousel>
        <Carousel.Item>
          <img src={ferrari} className="d-block carousel-item" alt='car'/>
        </Carousel.Item>
        <Carousel.Item>
          <img src={landscape6} className="d-block carousel-item" alt='car'/>
        </Carousel.Item>
        <Carousel.Item>
          <img src={landscape3} className="d-block carousel-item" alt='car'/>
        </Carousel.Item>
        <Carousel.Item>
          <img src={landscape4} className="d-block carousel-item" alt='car'/>
        </Carousel.Item>
        <Carousel.Item>
          <img src={landscape5} className="d-block carousel-item" alt='car'/>
        </Carousel.Item>
        <Carousel.Item>
          <img src={landscape1} className="d-block carousel-item" alt='car'/>
        </Carousel.Item>
        <Carousel.Item>
          <img src={landscape7} className="d-block carousel-item" alt='car'/>
        </Carousel.Item>
        <Carousel.Item>
          <img src={landscape8} className="d-block carousel-item" alt='car'/>
        </Carousel.Item>
        <Carousel.Item>
          <img src={landscape9} className="d-block carousel-item" alt='car'/>
        </Carousel.Item>
        <Carousel.Item>
          <img src={landscape10} className="d-block carousel-item" alt='car'/>
        </Carousel.Item>
        {/* <Carousel.Item>
          <img src={landscape11} className="d-block carousel-item" alt='car'/>
        </Carousel.Item>
        <Carousel.Item>
          <img src={landscape12} className="d-block carousel-item" alt='car'/>
        </Carousel.Item> */}
        <Carousel.Item>
          <img src={landscape15} className="d-block carousel-item" alt='car'/>
        </Carousel.Item>
        <Carousel.Item>
          <img src={landscape16} className="d-block carousel-item" alt='car'/>
        </Carousel.Item>
        <Carousel.Item>
          <img src={landscape17} className="d-block carousel-item" alt='car'/>
        </Carousel.Item>
        <Carousel.Item>
          <img src={landscape18} className="d-block carousel-item" alt='car'/>
        </Carousel.Item>
        <Carousel.Item>
          <img src={landscape19} className="d-block carousel-item" alt='car'/>
        </Carousel.Item>
        <Carousel.Item>
          <img src={landscape20} className="d-block carousel-item" alt='car'/>
        </Carousel.Item>
        {/* <Carousel.Item>
          <img src={landscape21} className="d-block carousel-item" alt='car'/>
        </Carousel.Item>
        <Carousel.Item>
          <img src={landscape22} className="d-block carousel-item" alt='car'/>
        </Carousel.Item> */}
        <Carousel.Item>
          <img src={landscape13} className="d-block carousel-item" alt='car'/>
          <Carousel.Caption>
            <h3>Before</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={landscape14} className="d-block carousel-item" alt='car'/>
          <Carousel.Caption>
            <h3>After</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  )
}

export default Gallery