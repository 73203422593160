import React from 'react'
import { IoLogoInstagram, IoLogoFacebook } from 'react-icons/io'
import { MdEmail } from 'react-icons/md'
import { FaPhoneSquare } from 'react-icons/fa'

const Contact = () => {
  return (
    <div id='contact' className='content'>
      <div className='tech-list'>
        <a href='https://www.facebook.com/profile.php?id=100081337214564' target='_blank' rel='noopener noreferrer'>
          < IoLogoFacebook size={67}/>
        </a>
        <a href='https://www.instagram.com/mh.valeting/' target='_blank' rel='noopener noreferrer'>
          < IoLogoInstagram size={70}/>
        </a>
        <a href='mailto:markhudsonvaleting@hotmail.com' target='_blank' rel='noopener noreferrer'>
          < MdEmail size={70}/>
        </a>
        <a id='phone' href='tel:07597703834' target='_blank' rel='noopener noreferrer'>
          < FaPhoneSquare size={55}/>
        </a>
      </div>
    </div>
  )
}

export default Contact