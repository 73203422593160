import React from 'react'
import Accordion from 'react-bootstrap/Accordion'

const Packages = () => {
  return (
    <div id='packages' className='content'>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header><b>Professional Wash</b>&nbsp; £30 - 1½ Hours</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Pre-wash (Traffic Film Remover) used on car and wheels</li>
              <li>Jet Wash to remove Pre-wash</li>
              <li>Two Bucket Hand Wash with Microfibre mits and Grit Guards including door and boot shuts</li>
              <li>Jet Wash to remove Two Bucket Wash</li>
              <li>Bodywork dried using microfibre drying towel</li>
              <li>Paint protection applied</li>
              <li>Tyres dressed to give new tyre look</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header><b>Mini Valet</b>&nbsp; £45 - 3 Hours</Accordion.Header>
          <Accordion.Body>
            <p>This package includes every service in the Professional Wash package, additional services are indicated with a +.</p>
            <ul>
              <li>Pre-wash (Traffic Film Remover) used on car and wheels</li>
              <li>Jet Wash to remove Pre-wash</li>
              <li>Two Bucket Hand Wash with Microfibre mits and Grit Guards including door and boot shuts</li>
              <li>Jet Wash to remove Two Bucket Wash</li>
              <li>Bodywork dried using microfibre drying towel</li>
              <li>Paint protection applied</li>
              <li>Tyres dressed to give new tyre look</li>
              <ul>+ Vacuum Carpets and Mats</ul>
              <ul>+ Vacuum Seats and Boot</ul>
              <ul>+ Windows cleaned</ul>
              <ul>+ New Car Scent applied (optional)</ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header><b>Premium Valet</b>&nbsp; £140 - 5 Hours</Accordion.Header>
          <Accordion.Body>
            <p>This package includes every service in the Professional Wash & Mini Valet packages, additional services are indicated with a +.</p>
            <ul>
              <li>Pre-wash (Traffic Film Remover) used on car and wheels</li>
              <li>Jet Wash to remove Pre-wash</li>
              <li>Two Bucket Hand Wash with Microfibre mits and Grit Guards including door and boot shuts</li>
              <li>Jet Wash to remove Two Bucket Wash</li>
              <li>Bodywork dried using microfibre drying towel</li>
              <li>Paint protection applied</li>
              <li>Tyres dressed to give new tyre look</li>
              <li>Vacuum Carpets and Mats</li>
              <li>Vacuum Seats and Boot</li>
              <li>Windows cleaned</li>
              <li>New Car Scent applied (optional)</li>
              <ul>+ Wheel treatment to remove break dust, iron particles etc</ul>
              <ul>+ Wheels hand washed</ul>
              <ul>+ Hot Air Blower Dry for windows, mirrors, wheels, trims & badges</ul>
              <ul>+ Wheels waxed </ul>
              <ul>+ Exterior trims treated with colour enhancer</ul>
              <ul>+ Arches cleaned and dressed</ul>
              <ul>+ Window protection applied</ul>
              <ul>+ Steam clean seats and carpets</ul>
              <ul>+ Condition leather on seats and trims</ul>
              <ul>+ Window protection applied</ul>
              <ul>+ Dress dashboard, centre console and door panels </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header><b>Ultimate Valet</b>&nbsp; £280 - 8 Hours</Accordion.Header>
          <Accordion.Body>
            <p>This package includes every service in the Professional Wash, Mini Valet & Premium Valet packages, additional services are indicated with a +.</p>
            <ul>
              <li>Pre-wash (Traffic Film Remover) used on car and wheels</li>
              <li>Jet Wash to remove Pre-wash</li>
              <li>Two Bucket Hand Wash with Microfibre mits and Grit Guards including door and boot shuts</li>
              <li>Jet Wash to remove Two Bucket Wash</li>
              <li>Bodywork dried using microfibre drying towel</li>
              <li>Paint protection applied</li>
              <li>Tyres dressed to give new tyre look</li>
              <li>Vacuum Carpets and Mats</li>
              <li>Vacuum Seats and Boot</li>
              <li>Windows cleaned</li>
              <li>New Car Scent applied (optional)</li>
              <li>Wheel treatment to remove break dust, iron particles etc</li>
              <li>Wheels hand washed</li>
              <li>Hot Air Blower Dry for windows, mirrors, wheels, trims & badges</li>
              <li>Wheels waxed </li>
              <li>Exterior trims treated with colour enhancer</li>
              <li>Arches cleaned and dressed</li>
              <li>Window protection applied</li>
              <li>Steam clean seats and carpets</li>
              <li>Condition leather on seats and trims</li>
              <li>Window protection applied</li>
              <li>Dress dashboard, centre console and door panels </li>
              <ul>+ Clay Bar to remove remaining paint contamination</ul>
              <ul>+ Compound paintwork to remove shallow scratches/swirl marks</ul>
              <ul>+ Machine Polish to smooth out paintwork and correct imperfections</ul>
              <ul>+ Clean headlining</ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header><b>Additional Services</b>&nbsp; Price dependant on condition</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Headlight Lenses Refurbed</li>
              <li>Engine Bay Cleaned and Treated</li>
              <li>Soft-top Clean and Conditioned</li>
              <li>Maintanance wash</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <p className='disclaimer'>*Prices may vary due to size and condition of vehicle</p>
    </div >
  )
}

export default Packages